import React from "react";
import { Route, Switch } from "react-router-dom";
import ModuleRoutes from "./Routes";
import { useAuth } from "../../auth";
import { AppsList, AppsListAdmin } from "./AppsListAdmin";
import { AppsDirectoryAPI } from "./AppsDirectoryAPI";
import { BONotificationPayload } from "../../app/Notifications";
import { AdminAppsConfigurations } from "./Configuration/AdminAppsConfigurations";

export function AppsModule(props: {
  notify: (notification: BONotificationPayload) => void;
}) {
  const auth = useAuth();
  const token = auth.user.token;
  const appsDirectory = new AppsDirectoryAPI(token);
  return (
    <Switch>
      <Route path={ModuleRoutes().apps} exact>
        {auth.control.userCan(auth.user, [
          {
            action: "edit",
            subject: "apps",
          },
        ]) ? (
          <AppsListAdmin appsDirectory={appsDirectory} notify={props.notify} />
        ) : (
          <AppsList appsDirectory={appsDirectory} />
        )}
      </Route>
      <Route path={ModuleRoutes(":clientId").configure}>
        {auth.control.userCan(auth.user, [
          {
            action: "edit",
            subject: "apps",
          },
        ]) && (
          <AdminAppsConfigurations
            AppsDirectory={appsDirectory}
            notify={props.notify}
          />
        )}
      </Route>
    </Switch>
  );
}
