import { AppRoutes } from "../app/Routes";
import React from "react";
import { CTACard, CTACardContent } from "../UIKit/CTACard";
import { useParams } from "react-router-dom";
import { useIntl } from "react-intl";

export function DashboardCMS() {
  const { clientId, supplierId } = useParams<{
    clientId: string;
    supplierId: string;
  }>();
  const intl = useIntl();

  const cardsContent: CTACardContent[] = [
    {
      title: intl.formatMessage({ id: "news.news", defaultMessage: "News" }),
      text: intl.formatMessage({
        id: "news.dashboard.news.text",
        defaultMessage:
          "This interface allows you to publish relevant news about your company activity or your business sector.",
      }),
      destination: AppRoutes(clientId, supplierId).news,
      buttonText: intl.formatMessage({
        id: "news.dashboard.news.cta",
        defaultMessage: "Manage your news",
      }),
      icon: "RssIcon",
      neededAbilities: [
        {
          action: "list",
          subject: "contentManagement",
          scope: { id: clientId },
        },
      ],
    },
    {
      title: intl.formatMessage({
        id: "videos.videos",
        defaultMessage: "Videos",
      }),
      text: intl.formatMessage({
        id: "videos.dashboard.videos.text",
        defaultMessage:
          "Share videos that you find interesting for your community",
      }),
      destination: AppRoutes(clientId, supplierId).videos,
      buttonText: intl.formatMessage({
        id: "news.dashboard.videos.cta",
        defaultMessage: "Manage your videos",
      }),
      icon: "FilmIcon",
      neededAbilities: [
        {
          action: "list",
          subject: "contentManagement",
          scope: { id: clientId },
        },
      ],
    },
    {
      title: intl.formatMessage({
        id: "exhibitions.exhibitions",
        defaultMessage: "Exhibitions",
      }),
      text: intl.formatMessage({
        id: "exhibitions.dashboard.exhibitions.text",
        defaultMessage:
          "Vous êtes présents à un salon ? Informez en les utilisateurs de votre application !",
      }),
      destination: AppRoutes(clientId, supplierId).exhibitions,
      buttonText: intl.formatMessage({
        id: "news.dashboard.exhibitions.cta",
        defaultMessage: "Manage your exhibitions",
      }),
      icon: "IdentificationIcon",
      neededAbilities: [
        {
          action: "list",
          subject: "contentManagement",
          scope: { id: clientId },
        },
      ],
    },
    {
      title: intl.formatMessage({
        id: "meetups.meetups",
        defaultMessage: "Meetings",
      }),
      text: intl.formatMessage({
        id: "meetups.dashboard.meetups.text",
        defaultMessage:
          "Organize meetups between your sales services and your professional users, and manage subscriptions.",
      }),

      destination: AppRoutes(clientId, supplierId).meetups,
      buttonText: intl.formatMessage({
        id: "news.dashboard.meetups.cta",
        defaultMessage: "Manage your meetings",
      }),
      icon: "ChatAlt2Icon",
      neededAbilities: [
        { action: "list", subject: "community", scope: { id: clientId } },
      ],
    },
    {
      title: intl.formatMessage({
        id: "productnews.dashboard.title",
        defaultMessage: "New products",
      }),
      text: intl.formatMessage({
        id: "productnews.dashboard.teaser",
        defaultMessage:
          "A new product is about to be released, new technical information makes a difference?  Let us know. This section will publish the information as a banner in the catalog area.",
      }),
      destination: AppRoutes(clientId, supplierId).productnews,
      buttonText: intl.formatMessage({
        id: "news.dashboard.productnews.cta",
        defaultMessage: "Manage your new products",
      }),
      icon: "StarIcon",
      neededAbilities: [
        { action: "list", subject: "catalogues", scope: { id: clientId } },
      ],
    },
  ];
  return (
    <div>
      <div className="mt-6">
        <h2 className="font-black text-4xl">
          {intl.formatMessage({
            id: "news.my.news",
            defaultMessage: "My news",
          })}
        </h2>
        <p className="text-2xl">
          {intl.formatMessage({
            id: "news.my.news.subtitle",
            defaultMessage: "Keep your community informed with these features",
          })}
          <div className="text-base">
            {intl.formatMessage({
              id: "news.my.news.subtitle.subtext",
              defaultMessage: "they won't appear unless you publish something",
            })}
          </div>
        </p>
        <hr className="mt-6 mb-6" />
        <div className="grid grid-cols-3 gap-4">
          {cardsContent.map((card) => (
            <CTACard content={card} />
          ))}
        </div>
      </div>
    </div>
  );
}
