import React, { useEffect, useState } from "react";
import { Route, Switch, useHistory, useParams } from "react-router-dom";
import ModuleRoutes from "./Routes";
import { useAuth } from "../../auth";
import { tableColumn } from "../../UIKit/Table";
import { FormattedMessage } from "react-intl";
import { ContributorsDirectoryAPI } from "./ContributorsDirectoryAPI";
import {
  Contributor,
  ContributorsDirectory,
  EMPTY_CONTRIBUTOR,
} from "./Contributors";
import { contributorDTO, ContributorForm } from "./ContributorForm";
import { ContributorsList } from "./ContributorsList";
import { Badge } from "../../UIKit/Badge";
import { BONotificationPayload } from "../../app/Notifications";
import { TeamRoutes } from "../../app/Routes";

const contributorsColumns: tableColumn<Contributor>[] = [
  {
    label: "contributors.name",
    content: (user: Contributor) => (
      <div>
        {user.firstname} {user.lastname}{" "}
        {user.isAppOwner && (
          <Badge
            label="contributors.account.owner"
            type="primary"
            flavor="filled"
          />
        )}
      </div>
    ),
  },
  {
    label: "contributors.email",
    content: (user: Contributor) => user.email,
  },
];

export function ContributorsModule(props: {
  notify: (not: BONotificationPayload) => void;
}) {
  const history = useHistory();
  const auth = useAuth();
  const token = auth.user.token;
  const { teamId } = useParams<{ teamId: string }>();
  const directory = new ContributorsDirectoryAPI(token);
  return (
    <Switch>
      <Route path={ModuleRoutes(":teamId").add} exact>
        <h1 className="text-2xl font-bold py-4 px-2 sm:px-0">
          <FormattedMessage
            id="contributors.add.title"
            defaultMessage="Add a user"
          />
        </h1>
        <ContributorForm
          directory={directory}
          teamId={teamId}
          awaitsValidation={true}
          data={EMPTY_CONTRIBUTOR}
          onSuccess={() => {
            history.push(TeamRoutes(teamId).contributors);
          }}
          notify={props.notify}
        />
      </Route>
      <Route path={ModuleRoutes(":teamId").edit(":id")}>
        <h1 className="text-2xl font-bold py-4 px-2 sm:px-0">
          <FormattedMessage
            id="contributors.edit.title"
            defaultMessage="Edit an user"
          />
        </h1>
        <EditContributor
          directory={directory}
          notify={props.notify}
          teamId={teamId}
        />
      </Route>
      <Route path={ModuleRoutes(":teamId").contributors} exact>
        <ContributorsList directory={directory} columns={contributorsColumns} />
      </Route>
    </Switch>
  );
}

function EditContributor(props: {
  directory: ContributorsDirectory;
  notify: (not: BONotificationPayload) => void;
  teamId: string;
}) {
  const [contributor, setContributor] = useState<Contributor>(
    EMPTY_CONTRIBUTOR
  );
  const { id } = useParams<{ id: string }>();
  useEffect(() => {
    (async () => setContributor(await props.directory.findOneById(id)))();
  }, [id]);
  const history = useHistory();

  const dto: contributorDTO = {
    id: contributor.id,
    teamId: props.teamId,
    lastname: contributor.lastname,
    firstname: contributor.firstname,
    email: contributor.email,
  };
  return (
    <ContributorForm
      directory={props.directory}
      teamId={props.teamId}
      awaitsValidation={true}
      data={dto}
      notify={props.notify}
      onSuccess={() => {
        history.push(TeamRoutes(props.teamId).contributors);
      }}
    />
  );
}
