import { APP_CONFIG } from "../../init";
import { APIClient } from "../../utilities/APIClient";
import {
  DealersImportPageResponse,
  DealersPageResponse,
  DealersProvider,
} from "./DealersProvider";
import { DealersImport, DealersImportConversionReport } from "./Dealers";
import { ConversionReportNotAvailable } from "./ConversionReportNotAvailable";
import { DateTime } from "luxon";

function toDealerImport(d: any): DealersImport {
  return { ...d, createdAt: DateTime.fromISO(d.createdAt) };
}

export class APIDealersImportProvider
  extends APIClient
  implements DealersProvider {
  constructor(token: string, clientId: string, private supplierId: string) {
    super(token);
  }

  findPaginatedDealersImports(
    firstIdx: number,
    nbPerPage: number
  ): Promise<DealersImportPageResponse> {
    // cursor based pagination is not activated in the API, so we use page numbers instead
    const itemsPerPageInTheAPI = 30; //constrained by the API
    const pageNumber = Math.trunc(firstIdx / itemsPerPageInTheAPI) + 1;
    return this.fetch(
      `${APP_CONFIG.API_BASE_URI}/suppliers/${this.supplierId}/dealer_imports?page=${pageNumber}&order[createdAt]=desc`
    )
      .then((response) => response.json())
      .then((data) => {
        return {
          items: data["hydra:member"].map((d: any) => {
            return toDealerImport(d);
          }),
          stats: { totalCount: data["hydra:totalItems"] },
        };
      });
  }

  downloadFile(id: string, filename: string) {
    this.triggerDownload(
      `${APP_CONFIG.API_BASE_URI}/suppliers/${this.supplierId}/dealer_imports/${id}/download`,
      filename
    );
  }

  downloadExport(): Promise<void> {
    return this.triggerDownload(
      `${APP_CONFIG.API_BASE_URI}/suppliers/${this.supplierId}/dealers.xlsx`,
      "dirbatDealersExport.xlsx"
    );
  }

  private triggerDownload(route: string, filename: string): Promise<void> {
    const link = document.createElement("a");
    return this.fetch(route)
      .then((res) => {
        return res.blob();
      })
      .then((blob) => {
        let objectUrl = window.URL.createObjectURL(blob);

        link.setAttribute("download", filename);
        link.href = objectUrl;
        link.click();

        window.URL.revokeObjectURL(objectUrl);
        return Promise.resolve();
      })
      .catch((reason) => {
        alert(reason);
      });
  }

  findPaginatedDealers(
    firstIdx: number,
    dealersPerPage: number,
    name?: string
  ): Promise<DealersPageResponse> {
    // cursor based pagination is not activated in the API, so we use page numbers instead
    const itemsPerPageInTheAPI = 30; //constrained by the API
    const pageNumber = Math.trunc(firstIdx / itemsPerPageInTheAPI) + 1;

    let filters = `?page=${pageNumber}`;

    if (name) {
      filters = `?page=${pageNumber}&name=${name}`;
    }

    return this.fetch(
      `${APP_CONFIG.API_BASE_URI}/suppliers/${this.supplierId}/dealers${filters}`
    )
      .then((response) => response.json())
      .then((data) => {
        return {
          items: data["hydra:member"],
          stats: { totalCount: data["hydra:totalItems"] },
        };
      });
  }

  add(file: File, cleanAfterImport: boolean): Promise<DealersImport> {
    const body = new FormData();
    body.append("importFile", file);
    body.append("cleanAfterImport", cleanAfterImport.toString());
    return this.fetch(
      `${APP_CONFIG.API_BASE_URI}/suppliers/${this.supplierId}/dealer_imports`,
      {
        method: "POST",
        body: body,
      }
    )
      .then((response) => response.json())
      .then((importData) => {
        return {
          status: importData.status,
          id: importData.id,
          progress: importData.progress,
          createdAt: DateTime.fromISO(importData.createdAt),
        };
      });
  }

  delete(id: string): Promise<void> {
    return this.fetch(
      `${APP_CONFIG.API_BASE_URI}/suppliers/${this.supplierId}/dealers/${id}`,
      {
        method: "DELETE",
      }
    ).then((response) => {
      if (!response.ok) {
        throw new Error("Could not delete dealer");
      }
    });
  }

  getReport(importId: string): Promise<DealersImportConversionReport> {
    return this.fetch(
      `${APP_CONFIG.API_BASE_URI}/suppliers/${this.supplierId}/dealer_imports/${importId}/report`
    )
      .then((response) => {
        if (response.status === 404) {
          throw new ConversionReportNotAvailable(importId);
        }
        return response.json();
      })
      .then((reportData) => reportData);
  }
}
