import { ContentProviderStats } from "../commonTypes";
import { contributorDTO } from "./ContributorForm";

export interface contributorsPageResponse {
  items: Contributor[];
  stats: ContentProviderStats;
}

export interface ContributorsDirectory {
  findPaginated: (
    teamId: string,
    firstIdx: number,
    cataloguesPerPage: number
  ) => Promise<contributorsPageResponse>;
  save: (
    contributorDTO: contributorDTO,
    teamId: string
  ) => Promise<Contributor>;
  findOneById: (id: string) => Promise<Contributor>;

  delete(contributor: Contributor): Promise<boolean>;
}

export interface Contributor {
  id: string;
  teamId: string;
  lastname: string;
  firstname: string;
  email: string;
  isAppOwner: boolean;
  isTeamOwner: boolean;
}

export const EMPTY_CONTRIBUTOR: Contributor = {
  email: "",
  lastname: "",
  firstname: "",
  id: "",
  teamId: "",
  isAppOwner: false,
  isTeamOwner: false,
};
