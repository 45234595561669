import { useHistory } from "react-router-dom";
import { Button } from "./Button";
import React from "react";
import { DynamicHeroIcon, IconName } from "./DynamicHeroIcon";
import { Ability, useAuth } from "../auth";
import { Application } from "../modules/Apps/Application";

export interface CTACardContent {
  title: string;
  text: string;
  destination: string;
  buttonText: string;
  icon?: IconName;
  neededAbilities: Ability[];
}

export function CTACard(props: {
  content: CTACardContent;
  application?: Application;
}) {
  const history = useHistory();
  const auth = useAuth();
  if (
    auth.control.userCan(
      auth.user,
      props.content.neededAbilities,
      props.application
    )
  ) {
    return (
      <div
        onClick={() => {
          history.push(props.content.destination);
        }}
        className="hover:bg-gray-50 rounded-md p-2"
      >
        {!!props.content.icon && (
          <div className="w-12">
            <DynamicHeroIcon icon={props.content.icon} width="12" />
          </div>
        )}
        <div>
          <h3 className="font-black text-2xl capitalize">
            {props.content.title}
          </h3>
          <p className="text-xl">{props.content.text}</p>
          <div className="flex justify-end mt-4">
            <Button
              onClick={() => history.push(props.content.destination)}
              label={props.content.buttonText}
            />
          </div>
        </div>
      </div>
    );
  } else {
    return <></>;
  }
}
