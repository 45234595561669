import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useQuery, useQueryClient } from "react-query";
import { Loading } from "../../UIKit/Loading";
import { CenterInScreen } from "../../UIKit/CenterInScreen";
import { Button } from "../../UIKit/Button";
import { PaginatedTable, tableColumn } from "../../UIKit/Table";
import RoutesContributors from "./Routes";
import { FormattedMessage, useIntl } from "react-intl";
import { Modal } from "../../UIKit/Modal";
import {
  Contributor,
  ContributorsDirectory,
  contributorsPageResponse,
} from "./Contributors";

interface ContributorsListProps {
  directory: ContributorsDirectory;
  columns: tableColumn<Contributor>[];
}

export function ContributorsList(props: ContributorsListProps) {
  const contributorsPerPage = 20;
  const history = useHistory();
  const intl = useIntl();
  const { teamId } = useParams<{ teamId: string }>();
  const [startingAt, setStartingAt] = useState<number>(1);
  const [contributorToDelete, triggerDeleteModal] = useState<
    Contributor | false
  >(false);
  const queryClient = useQueryClient();
  const {
    isFetching: contributorsAreFetching,
    data: contributorsPage,
    refetch,
  } = useQuery<contributorsPageResponse>(
    "contributors::all::page::" + startingAt,
    () => {
      return props.directory.findPaginated(
        teamId,
        (startingAt - 1) * contributorsPerPage + 1,
        contributorsPerPage
      );
    },
    { keepPreviousData: true } // To allow a smooth pagination
  );
  useEffect(() => {
    refetch();
  }, [teamId]);

  function loadContributorsSlice(number: number) {
    setStartingAt(number);
  }

  function deleteContributor(contributorToDelete: Contributor) {
    props.directory.delete(contributorToDelete).then((success) => {
      if (success) {
        refetch();
        triggerDeleteModal(false);
      } else {
      }
    });
  }

  if (!contributorsPage) {
    return (
      <div className="min-h-screen flex flex-col justify-center items-center">
        <Loading
          label={intl.formatMessage({
            id: "contributors.checking.contributors",
            defaultMessage: "Loading users...",
          })}
        />
      </div>
    );
  } else {
    const contributors = contributorsPage.items;
    const contributorsStats = contributorsPage.stats;
    return (
      <>
        {contributorToDelete && (
          <Modal
            onCancel={() => {
              triggerDeleteModal(false);
            }}
            onConfirm={() => deleteContributor(contributorToDelete)}
            altButtonLabel="cancel"
            mainButtonLabel="delete"
          >
            <div>
              <p className="font-bold text-xl">
                <FormattedMessage
                  id="contributors.delete.warning.title"
                  defaultMessage="Deleting a user"
                />
              </p>
              <p className="">
                <FormattedMessage
                  id="contributors.delete.warning.body"
                  defaultMessage="This irreversible operation will delete the account of this user. The created contents will not be modified."
                />
              </p>
            </div>
          </Modal>
        )}
        {contributors.length === 0 ? (
          <CenterInScreen>
            <div className="text-center mb-2">
              <p>
                <FormattedMessage
                  id="contributors.no.contributor.yet.1"
                  defaultMessage="You don't have created any user yet"
                />
              </p>
              <p>
                <FormattedMessage
                  id="contributors.no.contributor.yet.2"
                  defaultMessage="Invite your team!"
                />
              </p>
              <div className="pt-2">
                <Button
                  label={intl.formatMessage({
                    id: "contributors.create.first.cta",
                    defaultMessage: "Create your first user",
                  })}
                  type="primary"
                  onClick={() => history.push(RoutesContributors(teamId).add)}
                />
              </div>
            </div>
          </CenterInScreen>
        ) : (
          <div>
            <div className="flex justify-between mb-4 ml-2">
              <h2 className="text-xl font-bold">
                <FormattedMessage
                  id="contributors.count.plural"
                  defaultMessage="{num, plural, one {# user} other {# users}}"
                  values={{ num: contributorsStats.totalCount }}
                />
              </h2>
              {contributorsAreFetching && (
                <div className="items-center">
                  <Loading
                    label={intl.formatMessage({
                      id: "contributors.checking.contributors",
                      defaultMessage: "Loading users...",
                    })}
                  />
                </div>
              )}
            </div>
            <PaginatedTable<Contributor>
              onPageChange={loadContributorsSlice}
              totalItemsCount={contributorsStats.totalCount || 0}
              itemsPerPage={contributorsPerPage}
              data={contributors}
              page={startingAt}
              columns={[
                ...props.columns,
                {
                  label: "actions",
                  align: "right",
                  content: (contributor) => (
                    <div className="flex flex-row justify-end">
                      {!contributor.isAppOwner && (
                        <Button
                          size="tiny"
                          type="cancel"
                          iconName="XIcon"
                          onClick={() => {
                            triggerDeleteModal(contributor);
                          }}
                          label={intl.formatMessage({
                            id: "forms.delete",
                            defaultMessage: "delete",
                          })}
                        />
                      )}
                    </div>
                  ),
                },
              ]}
            />
            <div className="text-center mb-2 mt-4">
              <div>
                <Button
                  iconName="PlusIcon"
                  label="contributors.add.a.contributor"
                  onClick={(_e) => {
                    history.push(RoutesContributors(teamId).add);
                  }}
                />
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
}
