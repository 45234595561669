import {
  Contractor,
  ContractorsDirectory,
  contractorsPageResponse,
} from "./Contractors";
import { APP_CONFIG } from "../../init";
import { APIClient } from "../../utilities/APIClient";
import { DateTime } from "luxon";

function getContractor(contractorData: []): Contractor[] {
  return contractorData.map((nd: any) => mapContractorFromApi(nd));
}

function mapContractorFromApi(cd: any): Contractor {
  return {
    id: cd.id,
    lastname: cd.lastname,
    firstname: cd.firstname,
    email: cd.email,
    registrationDate: DateTime.fromISO(cd.createdAt),
    phone: "",
    siret: "",
    updatedAt: undefined,
    verificationDate:
      cd.verificationDate && DateTime.fromISO(cd.verificationDate),
  };
}

export class ContractorsDirectoryAPI
  extends APIClient
  implements ContractorsDirectory {
  constructor(token: string, clientId: string, private supplierId: string) {
    super(token);
  }
  findPaginated(
    firstIdx: number,
    contributorsPerPage: number
  ): Promise<contractorsPageResponse> {
    return this.fetch(
      `${APP_CONFIG.API_BASE_URI}/suppliers/${
        this.supplierId
      }/contractor_users?page=${Math.floor(firstIdx / contributorsPerPage) + 1}`
    )
      .then((response) => response.json())
      .then((contractorsData) => {
        return {
          items: getContractor(contractorsData["hydra:member"]),
          stats: { totalCount: contractorsData["hydra:totalItems"] },
        };
      });
  }
}
