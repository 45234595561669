import { APP_CONFIG } from "../../init";
import { APIClient } from "../../utilities/APIClient";
import { DateTime } from "luxon";
import { contentPageResponse } from "../commonTypes";
import { Exhibition, ExhibitionCalendar, exhibitionDTO } from "./Exhibition";
import { handleErrors } from "../../app/APIError";

function getExhibition(exhibitionData: []): Exhibition[] {
  return exhibitionData.map((ed: any) => mapExhibitionFromApi(ed));
}

function mapExhibitionFromApi(ed: any): Exhibition {
  return {
    id: ed.id,
    publicationDate: ed.publishedAt
      ? DateTime.fromISO(ed.publishedAt)
      : DateTime.local(),
    removalDate: ed.unpublishedAt
      ? DateTime.fromISO(ed.unpublishedAt)
      : DateTime.local(),
    status: ed.status,
    title: ed.title,
    triggersNotification: ed.triggersNotification,
    url: ed.url,
    end: ed.end ? DateTime.fromISO(ed.end) : DateTime.local(),
    location: ed.location,
    start: ed.start ? DateTime.fromISO(ed.start) : DateTime.local(),
  };
}

export class ExhibitionsCalendarAPI
  extends APIClient
  implements ExhibitionCalendar {
  constructor(token: string, clientId: string, private supplierId: string) {
    super(token);
  }
  findPaginated(
    firstIdx: number,
    itemsPerPage: number
  ): Promise<contentPageResponse<Exhibition>> {
    return this.fetch(
      `${APP_CONFIG.API_BASE_URI}/suppliers/${
        this.supplierId
      }/exhibitions?page=${Math.floor(firstIdx / itemsPerPage) + 1}`
    )
      .then((response) => response.json())
      .then((exhibitionData) => {
        return {
          items: getExhibition(exhibitionData["hydra:member"]),
          stats: { totalCount: exhibitionData["hydra:totalItems"] },
        };
      });
  }

  findOneById(id: string): Promise<Exhibition> {
    return this.fetch(
      `${APP_CONFIG.API_BASE_URI}/suppliers/${this.supplierId}/exhibitions/${id}`
    )
      .then((response) => response.json())
      .then(
        (exhibitionData): Exhibition => mapExhibitionFromApi(exhibitionData)
      );
  }

  delete(exhibition: Exhibition): Promise<boolean> {
    return this.fetch(
      `${APP_CONFIG.API_BASE_URI}/suppliers/${this.supplierId}/exhibitions/${exhibition.id}`,
      {
        method: "delete",
      }
    )
      .then((response) => {
        return response.status === 204;
      })
      .then((success): boolean => success);
  }

  save(dto: exhibitionDTO): Promise<Exhibition> {
    const method = dto.id ? "PUT" : "POST";
    const endPointUrl = dto.id
      ? `${APP_CONFIG.API_BASE_URI}/suppliers/${this.supplierId}/exhibitions/${dto.id}`
      : `${APP_CONFIG.API_BASE_URI}/suppliers/${this.supplierId}/exhibitions`;

    const init: RequestInit = {
      method: method,
      body: JSON.stringify({
        url: dto.url,
        title: dto.title,
        location: dto.location,
        start: dto.start?.toUTC().toISO(),
        triggersNotification: dto.triggersNotification,
        end: dto.end?.toUTC().toISO(),
        publishedAt: dto.publicationDate?.startOf("day").toUTC().toISO(),
        unpublishedAt: dto.removalDate?.endOf("day").toUTC().toISO(),
        status: dto.status,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    };

    return this.fetch(endPointUrl, init)
      .then(handleErrors)
      .then((exhibitionData) => {
        return mapExhibitionFromApi(exhibitionData);
      })
      .catch((e: Error) =>
        Promise.reject(JSON.parse(e.message)["hydra:description"])
      );
  }
}
