import React, { useState } from "react";
import { useQuery } from "react-query";
import { CenterInScreen } from "../../UIKit/CenterInScreen";
import { PaginatedTable, tableColumn } from "../../UIKit/Table";
import { Application, AppsDirectory, appsPageResponse } from "./Application";
import { FormattedMessage, useIntl } from "react-intl";
import { Loading } from "../../UIKit/Loading";
import { Button } from "../../UIKit/Button";
import { Modal } from "../../UIKit/Modal";
import { BONotificationPayload } from "../../app/Notifications";
import { CloneAppForm, CloneAppFormState } from "./CloneAppForm";
import { useHistory } from "react-router-dom";
import { AppRoutes, TeamRoutes } from "../../app/Routes";
import adminRoutes from "./Routes";

interface AppsListProps {
  appsDirectory: AppsDirectory;
  notify: (notification: BONotificationPayload) => void;
}

const columnsWithoutActions: tableColumn<Application>[] = [
  {
    label: "",
    content: (app: Application) =>
      app.supplierLogo ? (
        <div
          style={{ backgroundColor: app.secondaryColor }}
          className="p-4 rounded flex justify-center"
        >
          <img
            src={`data:image/svg+xml;utf8,${encodeURIComponent(
              app.supplierLogo
            )}`}
            alt={`logo ${app.name}`}
            className="w-12"
          />
        </div>
      ) : (
        <></>
      ),
  },
  {
    label: "apps.title",
    content: (app: Application) => (
      <div>
        <strong>{app.name}</strong>
        <br />
      </div>
    ),
  },
  {
    label: "apps.country",
    content: (app: Application) => (
      <div>
        <strong>{app.countryCode}</strong>
        <br />
      </div>
    ),
  },
  {
    label: "apps.language",
    content: (app: Application) => (
      <div>
        <strong>{app.languageCode}</strong>
        <br />
      </div>
    ),
  },
];

function ModalClone(props: {
  close: () => void;
  sourceApp: Application;
  appsDirectory: AppsDirectory;
  notify: (notification: BONotificationPayload) => void;
}) {
  const [formIsValid, setFormIsValid] = useState(false);
  const [state, setState] = useState<CloneAppFormState>({
    identifier: "",
    supplierName: "",
    appName: "",
  });
  const [submitting, setSubmitting] = useState<boolean>(false);

  function submit() {
    setSubmitting(true);
    props.appsDirectory
      .clone({
        newIdentifier: state.identifier,
        sourceAppId: props.sourceApp.id,
        newSupplierName: state.supplierName,
        newAppName: state.appName,
      })
      .then((_s) => {
        setSubmitting(false);
        props.notify({
          message: "Clonage OK",
          type: "success",
        });
        props.close();
      })
      .catch((e: Error) => {
        props.notify({
          message: e.message,
          type: "error",
        });
        props.close();
      });
  }

  return (
    <Modal
      onCancel={props.close}
      disabled={!formIsValid || submitting}
      onConfirm={submit}
      altButtonLabel="cancel"
      mainButtonLabel="apps.clone"
      loading={submitting}
    >
      <CloneAppForm
        sourceApp={props.sourceApp}
        onChange={(validationStatus, newState) => {
          setFormIsValid(validationStatus);
          setState(newState);
        }}
        value={state}
        submitting={submitting}
      />
    </Modal>
  );
}

export function AppsList(props: { appsDirectory: AppsDirectory }) {
  const history = useHistory();
  const intl = useIntl();

  const columns = [
    ...columnsWithoutActions,
    {
      label: intl.formatMessage({
        id: "apps.dashboard",
        defaultMessage: "Dashboard",
      }),
      content: (app: Application) => (
        <>
          <Button
            size="small"
            label={intl.formatMessage({
              id: "apps.dashboard",
              defaultMessage: "Dashboard",
            })}
            type={"secondary"}
            onClick={() =>
              history.push(AppRoutes(app.clientId, app.supplierId).appHome)
            }
            iconName="TemplateIcon"
          />
        </>
      ),
    },
  ];
  const appsPerPage = 20;
  const [startingAt, setStartingAt] = useState<number>(1);
  const {
    isFetching: appsAreFetching,
    data: appsPage,
  } = useQuery<appsPageResponse>(
    "apps::all::page::" + startingAt,
    () => {
      return props.appsDirectory.findPaginated(
        (startingAt - 1) * appsPerPage + 1,
        appsPerPage
      );
    },
    { keepPreviousData: true } // To allow a smooth pagination
  );

  function loadAppsSlice(number: number) {
    setStartingAt(number);
  }

  if (!appsPage) {
    return (
      <div className="min-h-screen flex flex-col justify-center items-center">
        <Loading
          label={intl.formatMessage({
            id: "apps.checking.apps",
            defaultMessage: "Loading apps list",
          })}
        />
      </div>
    );
  } else {
    const apps = appsPage.items;
    const appsStats = appsPage.stats;
    return (
      <>
        {apps.length === 0 ? (
          <CenterInScreen>
            <div className="text-center mb-2">
              <p>
                <FormattedMessage
                  id="apps.no.app.yet.1"
                  defaultMessage="You don't have any app yet"
                />
              </p>
              <p>
                <FormattedMessage
                  id="apps.no.app.yet.2"
                  defaultMessage="Let's get to work!"
                />
              </p>
            </div>
          </CenterInScreen>
        ) : (
          <div>
            <div className="flex justify-between mb-4 ml-2">
              <h2 className="text-xl font-bold">
                <FormattedMessage
                  id="apps.count.plural"
                  defaultMessage="{num, plural, one {# app} other {# apps}}"
                  values={{ num: appsStats.totalCount }}
                />
              </h2>
              {appsAreFetching && (
                <div className="items-center">
                  <Loading
                    label={intl.formatMessage({
                      id: "apps.checking.apps",
                      defaultMessage: "Loading apps list",
                    })}
                  />
                </div>
              )}
            </div>
            <PaginatedTable<Application>
              onPageChange={loadAppsSlice}
              totalItemsCount={appsStats.totalCount || 0}
              itemsPerPage={appsPerPage}
              data={apps}
              page={startingAt}
              columns={columns}
            />
          </div>
        )}
      </>
    );
  }
}

export function AppsListAdmin(props: AppsListProps) {
  const [appToDeploy, toggleAppToDeploy] = useState<Application | false>(false);
  const [appToClone, setAppToClone] = useState<Application | false>(false);

  const history = useHistory();
  const intl = useIntl();

  function triggerDeploy(app: Application) {
    props.appsDirectory
      .triggerDeploy(app)
      .then((succedeed) => {
        if (succedeed) {
          props.notify({
            message: intl.formatMessage({
              defaultMessage: "Deployment requested",
              id: "apps.deployment.requested",
            }),
            type: "success",
          });
        } else {
          props.notify({
            message: intl.formatMessage({
              defaultMessage: "Deployment couldn't be requested",
              id: "apps.deployment.request.failure",
            }),
            type: "error",
          });
        }
      })
      .catch((_e) => {
        props.notify({
          message: intl.formatMessage({
            defaultMessage: "Error while trying to deploy",
            id: "apps.deployment.failure",
          }),
          type: "error",
        });
      })
      .finally(() => {
        toggleAppToDeploy(false);
      });
  }

  const columns = [
    ...columnsWithoutActions,
    {
      label: "apps.status",
      content: (app: Application) => app.status,
    },
    {
      label: "apps.details",
      content: (app: Application) => (
        <div className="text-left">
          <strong>Identifier</strong> : {app.identifier}
          <br />
          <strong>
            <FormattedMessage id="apps.id" defaultMessage="ClientID" />
          </strong>{" "}
          : {app.clientId}
        </div>
      ),
    },
    {
      label: "contributors.contributors",
      content: (app: Application) => (
        <>
          <Button
            size="small"
            label="contributors.contributors"
            type={"secondary"}
            onClick={() => history.push(TeamRoutes(app.teamId).contributors)}
            iconName="UserGroupIcon"
          />
        </>
      ),
    },
    {
      label: intl.formatMessage({
        id: "apps.dashboard",
        defaultMessage: "Dashboard",
      }),
      content: (app: Application) => (
        <>
          <Button
            size="small"
            label={intl.formatMessage({
              id: "apps.dashboard",
              defaultMessage: "Dashboard",
            })}
            type={"secondary"}
            onClick={() =>
              history.push(AppRoutes(app.clientId, app.supplierId).appHome)
            }
            iconName="TemplateIcon"
          />
        </>
      ),
    },
    {
      label: "apps.actions",
      content: (app: Application) => (
        <div className="flex flex-col gap-1">
          <Button
            size="small"
            label="apps.clone"
            type={"secondary"}
            onClick={(_e) => setAppToClone(app)}
            iconName="DocumentDuplicateIcon"
          />
          <Button
            size="small"
            label="apps.deploy"
            onClick={(_e) => toggleAppToDeploy(app)}
            iconName="PaperAirplaneIcon"
          />
          <Button
            size="small"
            label="apps.configure"
            onClick={(_e) => history.push(adminRoutes(app.clientId).configure)}
            iconName="AdjustmentsIcon"
          />
        </div>
      ),
    },
  ];
  const appsPerPage = 20;
  const [startingAt, setStartingAt] = useState<number>(1);
  const {
    isFetching: appsAreFetching,
    data: appsPage,
    refetch,
  } = useQuery<appsPageResponse>(
    "apps::all::page::" + startingAt,
    () => {
      return props.appsDirectory.findPaginated(
        (startingAt - 1) * appsPerPage + 1,
        appsPerPage
      );
    },
    { keepPreviousData: true } // To allow a smooth pagination
  );

  function loadAppsSlice(number: number) {
    setStartingAt(number);
  }

  if (!appsPage) {
    return (
      <div className="min-h-screen flex flex-col justify-center items-center">
        <Loading
          label={intl.formatMessage({
            id: "apps.checking.apps",
            defaultMessage: "Loading apps list",
          })}
        />
      </div>
    );
  } else {
    const apps = appsPage.items;
    const appsStats = appsPage.stats;
    return (
      <>
        {appToDeploy && (
          <Modal
            onCancel={() => {
              toggleAppToDeploy(false);
            }}
            onConfirm={() => triggerDeploy(appToDeploy)}
            altButtonLabel="cancel"
            mainButtonLabel="apps.deploy"
          >
            <div>
              <p className="font-bold text-xl">
                <FormattedMessage
                  id="app.deploy.warning.title"
                  defaultMessage="Deploy «{appname}»"
                  values={{ appname: appToDeploy.name }}
                />
              </p>
              <p className="">
                <FormattedMessage
                  id="app.deploy.warning.body"
                  defaultMessage="Souhaitez vous réellement déclencher le build et le déploiement sur les stores de cette application ?"
                />
              </p>
            </div>
          </Modal>
        )}
        {appToClone && (
          <ModalClone
            close={() => {
              setAppToClone(false);
              refetch();
            }}
            sourceApp={appToClone}
            appsDirectory={props.appsDirectory}
            notify={props.notify}
          />
        )}
        {apps.length === 0 ? (
          <CenterInScreen>
            <div className="text-center mb-2">
              <p>
                <FormattedMessage
                  id="apps.no.app.yet.1"
                  defaultMessage="You don't have any app yet"
                />
              </p>
              <p>
                <FormattedMessage
                  id="apps.no.app.yet.2"
                  defaultMessage="Let's get to work!"
                />
              </p>
            </div>
          </CenterInScreen>
        ) : (
          <div>
            <div className="flex justify-between mb-4 ml-2">
              <h2 className="text-xl font-bold">
                <FormattedMessage
                  id="apps.count.plural"
                  defaultMessage="{num, plural, one {# app} other {# apps}}"
                  values={{ num: appsStats.totalCount }}
                />
              </h2>
              {appsAreFetching && (
                <div className="items-center">
                  <Loading
                    label={intl.formatMessage({
                      id: "apps.checking.apps",
                      defaultMessage: "Loading apps list",
                    })}
                  />
                </div>
              )}
            </div>
            <PaginatedTable<Application>
              onPageChange={loadAppsSlice}
              totalItemsCount={appsStats.totalCount || 0}
              itemsPerPage={appsPerPage}
              data={apps}
              page={startingAt}
              columns={columns}
            />
          </div>
        )}
      </>
    );
  }
}
