import { APP_CONFIG } from "../../init";
import { APIClient } from "../../utilities/APIClient";
import { Offer, OffersDirectory, offersPageResponse } from "./Offers";

function getOffer(offerData: []): Offer[] {
  return offerData.map((od: any) => mapOfferFromApi(od));
}

function mapOfferFromApi(od: any): Offer {
  return {
    id: od.id,
    title: od.title,
    body: od.body,
    category: od.category,
    status: od.status,
    images: od.images,
    price: 0,
  };
}

export class OffersDirectoryAPI extends APIClient implements OffersDirectory {
  constructor(token: string, clientId: string, private supplierId: string) {
    super(token);
  }
  findPaginated(
    firstIdx: number,
    _offersPerPage: number
  ): Promise<offersPageResponse> {
    return this.fetch(
      `${APP_CONFIG.API_BASE_URI}/suppliers/${this.supplierId}/offers?page=${
        Math.floor(firstIdx / _offersPerPage) + 1
      }`
    )
      .then((response) => response.json())
      .then((offersData) => {
        return {
          items: getOffer(offersData["hydra:member"]),
          stats: { totalCount: offersData["hydra:totalItems"] },
        };
      });
  }

  publish(offer: Offer): Promise<boolean> {
    return this.fetch(
      `${APP_CONFIG.API_BASE_URI}/suppliers/${this.supplierId}/offers/${offer.id}/review`,
      {
        method: "PATCH",
        headers: {
          "Content-Type": "application/merge-patch+json",
        },
        body: JSON.stringify({ status: "published" }),
      }
    )
      .then((response) => {
        return response.status === 200;
      })
      .then((success): boolean => success);
  }

  reject(offer: Offer): Promise<boolean> {
    return this.fetch(
      `${APP_CONFIG.API_BASE_URI}/suppliers/${this.supplierId}/offers/${offer.id}/review`,
      {
        method: "PATCH",
        headers: {
          "Content-Type": "application/merge-patch+json",
        },
        body: JSON.stringify({ status: "blocked" }),
      }
    )
      .then((response) => {
        return response.status === 200;
      })
      .then((success): boolean => success);
  }
}
