import { PaginationNav } from "./Pagination";
import React, { ReactNode, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { DateTime } from "luxon";
import { BadgeFlavor, BadgeType } from "./Badge";
import { Schedulable } from "../modules/News/News";
import { dirbatLocale } from "../translations/translations";

function TableColumnHeader(props: { label: string; align?: string }) {
  return (
    <th
      scope="col"
      className={`px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider ${
        props.align ? "text-" + props.align : "text-center"
      }`}
    >
      {!!props.label && <FormattedMessage id={props.label} />}
    </th>
  );
}

export interface tableColumn<T> {
  label: string;
  content: keyof T | ((item: T, locale: dirbatLocale) => JSX.Element | string);
  align?: "left" | "right" | "center";
}

export interface TableProps<T> {
  data: T[];
  columns: tableColumn<T>[];
}

export function Table<T>(props: TableProps<T>) {
  return (
    <div className="flex flex-col">
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <TableBase<T> data={props.data} columns={props.columns} />
          </div>
        </div>
      </div>
    </div>
  );
}

function TableBase<T>(props: TableProps<T>) {
  const intl = useIntl();
  return (
    <table className="min-w-full divide-y divide-gray-200">
      <thead className="bg-gray-50">
        <tr>
          {props.columns.map((column, k) => (
            <TableColumnHeader
              label={column.label}
              align={column.align}
              key={k}
            />
          ))}
        </tr>
      </thead>
      <tbody className="bg-white divide-y divide-gray-200">
        {props.data.map((item, k) => {
          return (
            <tr key={k}>
              {props.columns.map((column, k) => {
                return (
                  <td
                    className={`px-6 py-4 ${
                      column.align ? column.align : "text-center"
                    }`}
                    key={k}
                    align={column.align}
                  >
                    {typeof column.content === "function"
                      ? column.content(item, intl.locale as dirbatLocale)
                      : item[column.content]}
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}

export function InMemoryPaginatedTable<T>(props: PaginatedTableProps<T>) {
  const [currentPage, setCurrentPage] = useState<number>(props.page || 1);
  const itemsPerPage = props.itemsPerPage || 10;
  const startNumber = (currentPage - 1) * itemsPerPage + 1;

  const endNumber = currentPage * itemsPerPage;
  const [data, setData] = useState<T[]>(props.data);

  function onPageChange(page: number) {
    setCurrentPage(page);
  }

  useEffect(() => {
    setData(props.data.slice(startNumber - 1, endNumber));
  }, [currentPage]);

  return (
    <PaginatedTable
      page={currentPage}
      itemsPerPage={itemsPerPage}
      totalItemsCount={props.totalItemsCount}
      data={data}
      columns={props.columns}
      onPageChange={onPageChange}
    />
  );
}

export interface PaginatedTableProps<T> extends TableProps<T> {
  itemsPerPage?: number;
  totalItemsCount: number;
  page?: number;
  onPageChange: (newPage: number) => void;
}

export function PaginatedTable<T>(props: PaginatedTableProps<T>) {
  const itemsPerPage = props.itemsPerPage || 10;
  const startNumber = ((props.page || 1) - 1) * itemsPerPage + 1;
  const endNumber = (props.page || 1) * itemsPerPage;

  function navigateTo(page: number) {
    props.onPageChange(page);
  }

  return (
    <div className="flex flex-col">
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <TableBase<T> columns={props.columns} data={props.data} />
            <div
              className="bg-white px-4 py-3 flex items-center justify-between border-t
                              border-gray-200 sm:px-6"
            >
              <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
                <div>
                  <p className="text-sm text-gray-700">
                    <FormattedMessage
                      id="results.from.to.total"
                      defaultMessage="results from <em>{from}</em> to <em>{to}</em> on <em>{total}</em>"
                      values={{
                        from: startNumber,
                        to: Math.min(endNumber, props.totalItemsCount),
                        total: props.totalItemsCount,
                        em: (v: ReactNode) => (
                          <span className="font-bold">{v}</span>
                        ),
                      }}
                    />
                  </p>
                </div>
                {props.totalItemsCount > itemsPerPage && (
                  <PaginationNav
                    current={props.page}
                    itemsPerPage={itemsPerPage}
                    totalItemsCount={props.totalItemsCount}
                    onNavigate={(page) => {
                      navigateTo(page);
                    }}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export function getStatusLabel(item: Schedulable): string {
  if (item.status === "draft") {
    return "draft";
  }
  if (item.removalDate < DateTime.local()) {
    return "finished";
  }
  if (item.publicationDate < DateTime.local()) {
    return "published";
  }
  return "scheduled";
}

export function getFlavorLabel(item: Schedulable): BadgeFlavor {
  if (status === "draft") {
    return "light";
  }
  if (item.removalDate < DateTime.local()) {
    return "outlined";
  }
  if (item.publicationDate > DateTime.local()) {
    return "light";
  }
  return "filled";
}

export function getBadgeType(item: Schedulable): BadgeType {
  if (item.status === "draft") {
    return "secondary";
  }
  if (item.removalDate < DateTime.local()) {
    return "ok";
  }
  return "ok";
}

export function getPublicationDateLabel(
  item: Schedulable,
  locale: dirbatLocale
) {
  if (item.status === "draft") return "-";
  if (item.publicationDate > DateTime.local()) {
    return (
      <>
        <div>{item.publicationDate.setLocale(locale).toRelative()}</div>
        <div className="text-xs">
          (
          {item.publicationDate
            .setLocale(locale)
            .toLocaleString(DateTime.DATE_SHORT)}
          )
        </div>
      </>
    );
  }
  return item.publicationDate.toLocaleString();
}

export function getRemovalDateLabel(item: Schedulable, locale: dirbatLocale) {
  if (item.status === "draft") return "-";
  if (
    item.removalDate > DateTime.local() &&
    item.publicationDate < DateTime.local()
  ) {
    return (
      <>
        <div>{item.removalDate.setLocale(locale).toRelative()}</div>
        <div className="text-xs">
          (
          {item.removalDate
            .setLocale(locale)
            .toLocaleString(DateTime.DATE_SHORT)}
          )
        </div>
      </>
    );
  }
  return `${item.removalDate.toLocaleString()}`;
}
