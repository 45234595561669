import { APP_CONFIG } from "../../init";
import { APIClient } from "../../utilities/APIClient";
import { DateTime } from "luxon";
import { Metric, MetricDTO, MetricsProvider, MetricValue } from "./Metric";
import { contentPageResponse } from "../commonTypes";

function getOneMetric(metricData: []): Metric[] {
  return metricData.map((metricDatum: any) => mapMetricFromApi(metricDatum));
}

function mapMetricFromApi(metricDatum: any): Metric {
  const metricValues: MetricValue[] = [];
  for (let i = 0; i < metricDatum.values.length; i++) {
    metricValues.push({
      value: metricDatum.values[i][1],
      date: metricDatum.values[i][0],
    });
  }
  return {
    name: metricDatum.name,
    values: metricValues,
    hint: metricDatum.hint,
    date: DateTime.fromISO(metricDatum.date),
  };
}

const EMPTY_METRIC: Metric = { name: "", values: [], date: DateTime.local() };

export class MetricsProviderAPI extends APIClient implements MetricsProvider {
  constructor(token: string, clientId: string, private supplierId: string) {
    super(token);
  }
  findPaginated(
    firstIdx: number,
    itemsPerPage: number
  ): Promise<contentPageResponse<Metric>> {
    return this.fetch(
      `${APP_CONFIG.API_BASE_URI}/suppliers/${this.supplierId}/metrics?page=${
        Math.floor(firstIdx / itemsPerPage) + 1
      }`
    )
      .then((response) => response.json())
      .then((metricsData) => {
        return {
          items: getOneMetric(metricsData["hydra:member"]),
          stats: { totalCount: metricsData["hydra:totalItems"] },
        };
      });
  }

  save(dto: MetricDTO) {
    return Promise.resolve(EMPTY_METRIC);
  }

  findOneById(id: string) {
    return Promise.resolve(EMPTY_METRIC);
  }
  delete(metric: Metric) {
    return Promise.resolve(false);
  }
}
