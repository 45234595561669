import React, { useEffect, useState } from "react";
import { BONotificationPayload } from "../../app/Notifications";
import { DateTime } from "luxon";
import { FormStepTitle } from "../../UIKit/form/FormStepTitle";
import { Input } from "../../UIKit/form/Input";
import { InputDate } from "../../UIKit/form/InputDate";
import { Button } from "../../UIKit/Button";
import {
  ExhibitionCalendar,
  exhibitionDTO,
  ExhibitionStatuses,
} from "./Exhibition";
import { SchedulingFormWidget } from "../../UIKit/form/SchedulingFormWidget";
import { NotificationFormWidget } from "../../UIKit/form/NotificationFormWidget";
import { useAuth } from "../../auth";
import { useIntl } from "react-intl";

export interface ExhibitionFormProps {
  awaitsValidation: true;
  data: exhibitionDTO;
  calendar: ExhibitionCalendar;
  onSuccess: () => void;
  notify: (not: BONotificationPayload) => void;
}

export function ExhibitionForm(props: ExhibitionFormProps) {
  let step = 1;
  const { user } = useAuth();
  const [awaitsValidation, setAwaitsValidation] = useState<boolean>(
    props.awaitsValidation
  );
  const [title, setTitle] = useState<string>(props.data.title);
  const [url, setUrl] = useState<string>(props.data.url);
  const [start, setStart] = useState<DateTime>(props.data.start);
  const [end, setEnd] = useState<DateTime>(props.data.end);
  const [location, setLocation] = useState<string>(props.data.location);
  const [publicationDate, setPublicationDate] = useState<DateTime>(
    props.data.publicationDate ? props.data.publicationDate : DateTime.local()
  );
  const [removalDate, setRemovalDate] = useState<DateTime>(
    props.data.removalDate ? props.data.removalDate : DateTime.local()
  );
  const [triggersNotification, setTriggersNotification] = useState<boolean>(
    props.data.triggersNotification
  );
  const intl = useIntl();
  const [status, setStatus] = useState<ExhibitionStatuses>(props.data.status);
  function isValid(): boolean {
    return (
      !!title &&
      !!location &&
      !!url &&
      !!end &&
      !!start &&
      !!publicationDate &&
      !!status
    );
  }
  useEffect(() => {
    setTitle(props.data.title);
    setLocation(props.data.location);
    setUrl(props.data.url);
    setEnd(props.data.end);
    setStart(props.data.start);
    setTriggersNotification(props.data.triggersNotification);
    setPublicationDate(props.data.publicationDate);
    props.data.removalDate && setRemovalDate(props.data.removalDate);
    setStatus(props.data.status);
  }, [props.data]);
  function onSubmit(_e: any) {
    setAwaitsValidation(false);
    if (isValid()) {
      const exhibitionDTO: exhibitionDTO = {
        title: title,
        url: url,
        end: end,
        start: start,
        triggersNotification: triggersNotification,
        publicationDate: publicationDate,
        removalDate: removalDate,
        id: props.data.id,
        status: status,
        location: location,
      };
      props.calendar
        .save(exhibitionDTO)
        .then((savedExhibition) => {
          props.onSuccess();
        })
        .catch((reason) => props.notify({ type: "error", message: reason }));
    }
  }
  return (
    <div className="mb-10">
      <div className="flex flex-rox mb-4">
        <div className="mb-4 w-2/3 pr-10">
          <FormStepTitle
            label={intl.formatMessage({
              id: "formstep.content",
              defaultMessage: "Content",
            })}
            step={step++}
            awaitsValidation={awaitsValidation}
          />
          <Input
            name="title"
            label={intl.formatMessage({
              id: "exhibitions.title",
              defaultMessage: "Title",
            })}
            value={title}
            isValid={!!title}
            required={true}
            onChange={(e: any) => {
              setTitle(e.target.value);
            }}
            awaitsValidation={awaitsValidation}
          />
          <Input
            name="location"
            label={intl.formatMessage({
              id: "exhibitions.location",
              defaultMessage: "Location",
            })}
            value={location}
            isValid={!!location}
            required={true}
            onChange={(e: any) => {
              setLocation(e.target.value);
            }}
            awaitsValidation={awaitsValidation}
          />
          <Input
            name="url"
            label={intl.formatMessage({
              id: "exhibitions.url",
              defaultMessage: "URL",
            })}
            value={url}
            isValid={!!url}
            onChange={(e: any) => {
              setUrl(e.target.value);
            }}
            required={true}
            awaitsValidation={awaitsValidation}
          />
          <InputDate
            name="start"
            label={intl.formatMessage({
              id: "exhibitions.start",
              defaultMessage: "Start date of the exhibition",
            })}
            isValid={!!start}
            date={start}
            onChange={(d: DateTime) => {
              setStart(d);
            }}
            withTime={true}
            required={true}
            awaitsValidation={awaitsValidation}
          />
          <InputDate
            name="end"
            label={intl.formatMessage({
              id: "exhibitions.end",
              defaultMessage: "End date of the exhibition",
            })}
            isValid={!!end}
            date={end}
            onChange={(d: DateTime) => {
              setEnd(d);
            }}
            withTime={true}
            required={true}
            awaitsValidation={awaitsValidation}
          />
        </div>
        <div className="mb-4 w-1/3">
          <FormStepTitle
            label={intl.formatMessage({
              id: "formstep.schedule",
              defaultMessage: "Schedule",
            })}
            step={step++}
            awaitsValidation={awaitsValidation}
          />
          <SchedulingFormWidget
            onChange={(data) => {
              setPublicationDate(data.publicationDate);
              setRemovalDate(data.removalDate);
              setStatus(data.publicationStatus);
            }}
            awaitsValidation={props.awaitsValidation}
            publicationDate={publicationDate}
            removalDate={removalDate}
            publicationStatus={status}
          />
          <NotificationFormWidget
            triggersNotification={triggersNotification}
            triggersImmediately={publicationDate < DateTime.local()}
            onChange={setTriggersNotification}
            notification={{
              applicationName: user.supplier,
              body: title,
              title: "Salon",
            }}
          />
        </div>
      </div>
      <div className="flex flex-row items-center">
        <Button
          onClick={onSubmit}
          disabled={false}
          iconName={props.data.id ? "CheckIcon" : "PlusIcon"}
          label={
            props.data.id ? "exhibitions.edit.submit" : "exhibitions.add.submit"
          }
        />
        <div className="ml-4">
          <Button
            onClick={() => props.onSuccess()}
            disabled={false}
            iconName="BanIcon"
            type="secondary"
            label="cancel"
            size="small"
          />
        </div>
      </div>
    </div>
  );
}
