import React from "react";
import { Route, Switch, useParams } from "react-router-dom";
import ModuleRoutes from "./Routes";
import { useAuth } from "../../auth";
import { tableColumn } from "../../UIKit/Table";
import { BONotificationPayload } from "../../app/Notifications";
import { Contractor } from "./Contractors";
import { ContractorsDirectoryAPI } from "./ContactorsDirectoryAPI";
import { ContractorsList } from "./ContractorsList";
import { CommunityDashboard } from "./CommunityDashboard";
import { Badge } from "../../UIKit/Badge";
import { OffersDirectoryAPI } from "./OffersDirectoryAPI";
import { offersColumns, OffersList } from "./OffersList";
import { messageColumns } from "./ContactMessagesList";
import { ContactMessage } from "./ContactMessages";
import { MailboxAPI } from "./MailboxAPI";
import { List } from "../../utilities/List";
import { useIntl } from "react-intl";

const contractorsColumns: tableColumn<Contractor>[] = [
  {
    label: "contractor.name",
    content: (contractor: Contractor) => (
      <div>
        {contractor.firstname} {contractor.lastname}
      </div>
    ),
  },
  {
    label: "contributors.email",
    content: (contractor: Contractor) => contractor.email,
  },
  {
    label: "contributors.registrationDate",
    content: (contractor: Contractor) =>
      contractor.registrationDate.toLocaleString(),
  },
  {
    label: "contributors.infos",
    content: (contractor: Contractor) =>
      contractor.verificationDate ? (
        <Badge label={"compte confirmé"} type="ok" />
      ) : (
        <Badge label="compte non confirmé" type="ko" />
      ),
  },
];

export function CommunityModule(props: {
  notify: (not: BONotificationPayload) => void;
}) {
  const intl = useIntl();
  const auth = useAuth();
  const token = auth.user.token;
  const { clientId, supplierId } = useParams<{
    clientId: string;
    supplierId: string;
  }>();
  const contractorsDirectory = new ContractorsDirectoryAPI(
    token,
    clientId,
    supplierId
  );
  const offersDirectory = new OffersDirectoryAPI(token, clientId, supplierId);
  const mailbox = new MailboxAPI(token, clientId, supplierId);

  return (
    <Switch>
      <Route path={ModuleRoutes(":clientId", ":supplierId").contractors} exact>
        <ContractorsList
          directory={contractorsDirectory}
          columns={contractorsColumns}
        />
      </Route>
      <Route path={ModuleRoutes(":clientId", ":supplierId").offers} exact>
        <OffersList
          directory={offersDirectory}
          columns={offersColumns}
          notify={props.notify}
        />
      </Route>
      <Route path={ModuleRoutes(":clientId", ":supplierId").contacts} exact>
        <List<ContactMessage>
          notify={props.notify}
          columns={messageColumns}
          directory={mailbox}
          messages={{
            empty1: intl.formatMessage({
              id: "messages.no.message.yet.1",
              defaultMessage: "No message received yet",
            }),
            empty2: intl.formatMessage({
              id: "messages.no.message.yet.2",
              defaultMessage: "...It won't last!",
            }),
            countPlural: "messages.count.plural",
            loading: intl.formatMessage({
              id: "messages.loading.messages",
              defaultMessage: "Loading messages",
            }),
          }}
        />
      </Route>
      <Route path={ModuleRoutes(":clientId", ":supplierId").dashboard}>
        <CommunityDashboard clientId={clientId} supplierId={supplierId} />
      </Route>
    </Switch>
  );
}
