import { menuItem } from "./UIKit/Header";
import { AppRoutes, TeamRoutes } from "./app/Routes";
import { abilities } from "./auth";
import CataloguesRoutes from "./modules/Catalogues/Routes";
import CommunityRoutes from "./modules/Community/Routes";
import SettingsRoutes from "./modules/Settings/Routes";
import { useIntl } from "react-intl";

export const suppliersMenu = (
  clientId: string,
  supplierId: string,
  teamId: string
): menuItem[] => {
  const menuItems: menuItem[] = [];
  const intl = useIntl();
  if (!clientId) {
    menuItems.push({
      route: TeamRoutes(teamId).apps,
      label: intl.formatMessage({
        id: "apps.applications",
        defaultMessage: "applications",
      }),
      iconName: "DeviceMobileIcon",
      neededAbilities: [abilities("")["apps:list"]],
    });
    menuItems.push({
      route: TeamRoutes(teamId).contributors,
      label: intl.formatMessage({
        id: "contributors.contributors",
        defaultMessage: "Users",
      }),
      iconName: "UserGroupIcon",
      neededAbilities: [abilities("")["contributors:list"]],
    });
  }

  if (!!clientId) {
    menuItems.push(
      {
        route: CataloguesRoutes(clientId, supplierId).dashboard,
        label: intl.formatMessage({
          id: "catalogues.dashboard.title",
          defaultMessage: "Catalogs and dealers",
        }),
        iconName: "CollectionIcon",
        neededAbilities: [abilities(clientId)["catalogues:list"]],
        children: [
          {
            route: CataloguesRoutes(clientId, supplierId).catalogues,
            label: intl.formatMessage({
              id: "catalogues.catalogues",
              defaultMessage: "Catalogs",
            }),
            neededAbilities: [abilities(clientId)["catalogues:list"]],
          },
          {
            route: CataloguesRoutes(clientId, supplierId).dealersImports,
            label: intl.formatMessage({
              id: "dealersImports.dealersImports",
              defaultMessage: "Dealers importations",
            }),
            neededAbilities: [abilities(clientId)["catalogues:list"]],
          },
        ],
      },
      {
        route: AppRoutes(clientId, supplierId).cms,
        label: intl.formatMessage({
          id: "news.my.news",
          defaultMessage: "My news",
        }),
        iconName: "SpeakerphoneIcon",
        neededAbilities: [abilities(clientId)["contentManagement:list"]],
        children: [
          {
            route: AppRoutes(clientId, supplierId).news,
            label: intl.formatMessage({
              id: "news.news",
              defaultMessage: "News",
            }),
            neededAbilities: [abilities(clientId)["contentManagement:list"]],
          },
          {
            route: AppRoutes(clientId, supplierId).videos,
            label: intl.formatMessage({
              id: "videos.videos",
              defaultMessage: "Videos",
            }),
            neededAbilities: [abilities(clientId)["contentManagement:list"]],
          },
          {
            route: AppRoutes(clientId, supplierId).exhibitions,
            label: intl.formatMessage({
              id: "exhibitions.exhibitions",
              defaultMessage: "Videos",
            }),

            neededAbilities: [abilities(clientId)["contentManagement:list"]],
          },
          {
            route: AppRoutes(clientId, supplierId).meetups,
            label: intl.formatMessage({
              id: "meetups.meetups",
              defaultMessage: "Meetings",
            }),
            neededAbilities: [abilities(clientId)["contentManagement:list"]],
          },
          {
            route: AppRoutes(clientId, supplierId).productnews,
            label: intl.formatMessage({
              id: "productnews.productnews",
              defaultMessage: "New products",
            }),

            neededAbilities: [abilities(clientId)["contentManagement:list"]],
          },
        ],
      },
      {
        route: AppRoutes(clientId, supplierId).community,
        label: intl.formatMessage({
          id: "community.title",
          defaultMessage: "My community",
        }),

        iconName: "UserGroupIcon",
        neededAbilities: [abilities(clientId)["communityManagement:list"]],
        children: [
          {
            route: CommunityRoutes(clientId, supplierId).contractors,
            label: intl.formatMessage({
              id: "community.contractors",
              defaultMessage: "The professionnals",
            }),
            neededAbilities: [abilities(clientId)["communityManagement:list"]],
          },
          {
            route: CommunityRoutes(clientId, supplierId).contacts,
            label: intl.formatMessage({
              id: "community.contacts",
              defaultMessage: "Messages received",
            }),
            neededAbilities: [abilities(clientId)["communityManagement:list"]],
          },
          {
            route: CommunityRoutes(clientId, supplierId).offers,
            label: intl.formatMessage({
              id: "community.offers",
              defaultMessage: "Offers",
            }),
            neededAbilities: [abilities(clientId)["communityManagement:list"]],
          },
        ],
      },
      {
        route: AppRoutes(clientId, supplierId).metrics,
        label: intl.formatMessage({
          id: "metrics.metrics",
          defaultMessage: "Metrics",
        }),
        iconName: "TrendingUpIcon",
        neededAbilities: [abilities(clientId)["privacyPolicy:edit"]],
      },
      {
        route: AppRoutes(clientId, supplierId).settings,
        label: intl.formatMessage({
          id: "settings.settings",
          defaultMessage: "Settings",
        }),
        iconName: "AdjustmentsIcon",
        neededAbilities: [abilities(clientId)["settings:list"]],
        children: [
          {
            label: intl.formatMessage({
              id: "settings.privacyPolicy.privacyPolicy",
              defaultMessage: "Privacy policy",
            }),
            neededAbilities: [abilities(clientId)["privacyPolicy:edit"]],
            route: SettingsRoutes(clientId, supplierId).privacyPolicy,
          },
          {
            route: SettingsRoutes(clientId, supplierId).generalParameters,
            label: intl.formatMessage({
              id: "settings.settings",
              defaultMessage: "Settings",
            }),
            neededAbilities: [abilities(clientId)["settings:edit"]],
          },
        ],
      },
      {
        route: AppRoutes(clientId, supplierId).documentation,
        label: intl.formatMessage({
          id: "help",
          defaultMessage: "help",
        }),
        iconName: "QuestionMarkCircleIcon",
        neededAbilities: [],
      }
    );
  }
  return menuItems;
};
