import React from "react";
import { Route, Switch, useHistory, useParams } from "react-router-dom";
import ModuleRoutes from "./Routes";
import { useAuth } from "../../auth";
import { FormattedMessage, useIntl } from "react-intl";
import { SettingsDirectoryAPI } from "./SettingsDirectoryAPI";
import { PrivacyPolicyForm } from "./PrivacyPolicyForm";
import { CTACard, CTACardContent } from "../../UIKit/CTACard";
import { BONotificationPayload } from "../../app/Notifications";
import { SupplierAppsConfigurations } from "./SupplierAppsConfigurations";

export const EMPTY_POLICY = {
  content: "",
};

export function SettingsModule(props: {
  notify: (not: BONotificationPayload) => void;
}) {
  const history = useHistory();
  const auth = useAuth();
  const token = auth.user.token;
  const { clientId, supplierId } = useParams<{
    clientId: string;
    supplierId: string;
  }>();
  const directory = new SettingsDirectoryAPI(token, clientId, supplierId);

  return (
    <Switch>
      <Route
        path={ModuleRoutes(":clientId", ":supplierId").privacyPolicy}
        exact
      >
        <h1 className="text-2xl font-bold py-4 px-2 sm:px-0">
          <FormattedMessage
            id="settings.privacyPolicy.title"
            defaultMessage="Your privacy policy"
          />
        </h1>
        <PrivacyPolicyForm
          directory={directory}
          awaitsValidation={true}
          data={EMPTY_POLICY}
          onSave={() => {
            history.push(ModuleRoutes(clientId, supplierId).privacyPolicy);
          }}
          notify={props.notify}
        />
      </Route>
      <Route path={ModuleRoutes(":clientId", ":supplierId").generalParameters}>
        <SupplierAppsConfigurations
          settingsDirectory={directory}
          notify={props.notify}
        />
      </Route>
      <Route path={ModuleRoutes(":clientId", ":supplierId").settings}>
        <SettingsDashboard clientId={clientId} supplierId={supplierId} />
      </Route>
    </Switch>
  );
}

function SettingsDashboard(props: { clientId: string; supplierId: string }) {
  const intl = useIntl();
  const cardsContent: CTACardContent[] = [
    {
      title: intl.formatMessage({
        id: "settings.privacyPolicy.privacyPolicy",
        defaultMessage: "Privacy policy",
      }),
      text: intl.formatMessage({
        id: "settings.privacyPolicy.privacyPolicy.subtitle",
        defaultMessage: "Manage your privacy policy contents",
      }),
      destination: ModuleRoutes(props.clientId, props.supplierId).privacyPolicy,
      buttonText: intl.formatMessage({
        id: "dashboard.privacyPolicy.cta",
        defaultMessage: "Manage your privacy policy",
      }),
      icon: "ShieldCheckIcon",
      neededAbilities: [
        {
          action: "edit",
          subject: "privacyPolicy",
          scope: { id: props.clientId },
        },
      ],
    },
    {
      title: intl.formatMessage({
        id: "settings.generalParameters.title",
        defaultMessage: "General settings",
      }),
      text: intl.formatMessage({
        id: "settings.generalParameters.subtitle",
        defaultMessage:
          "Contacts, messages, customize DirBat to fit your organization",
      }),
      destination: ModuleRoutes(props.clientId, props.supplierId)
        .generalParameters,
      buttonText: intl.formatMessage({
        id: "dashboard.parameters.cta",
        defaultMessage: "Customize your application",
      }),
      icon: "AdjustmentsIcon",
      neededAbilities: [
        { action: "list", subject: "settings", scope: { id: props.clientId } },
      ],
    },
  ];
  return (
    <div>
      <div className="mt-6">
        <h2 className="font-black text-4xl">
          {intl.formatMessage({
            id: "config.configure.your.app",
            defaultMessage: "Configure your application",
          })}
        </h2>
        <hr className="mt-6 mb-6" />
        <div className="grid grid-cols-3 gap-4">
          {cardsContent.map((card) => (
            <CTACard content={card} />
          ))}
        </div>
      </div>
    </div>
  );
}
