import { JWToken, UserData } from "../auth";
import { APP_CONFIG } from "../init";

export type authorizedFetch = (
  input: RequestInfo,
  init?: RequestInit
) => Promise<Response>;

export class NotAuthenticatedError extends Error {}
export class NotAuthorizedError extends Error {}
export class NotFoundError extends Error {}
export class ServerError extends Error {}

function getAPIFetch(token: JWToken): authorizedFetch {
  const headers = { Authorization: "Bearer " + token };
  return (input: RequestInfo, init: RequestInit | undefined) => {
    return window
      .fetch(input, {
        ...init,
        headers: {
          ...init?.headers,
          ...headers,
        },
      })
      .then((response) => {
        if (response.status === 404) {
          throw new NotFoundError("Not Found");
        }
        if (response.status === 403) {
          throw new NotAuthorizedError("Unauthorized");
        }
        if (response.status === 401) {
          throw new NotAuthenticatedError("Not authenticated");
        }
        if (response.status === 500) {
          throw new ServerError("Server error");
        }
        return response;
      });
  };
}

export class APIClient {
  public fetch: authorizedFetch;
  constructor(token: string) {
    this.fetch = getAPIFetch(token);
  }

  getUserData(): Promise<UserData> {
    return this.fetch(`${APP_CONFIG.API_BASE_URI}/users/current`)
      .then((response) => response.json())
      .then((userData): UserData => userData);
  }
}
