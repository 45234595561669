import {
  appConfigurationsBySections,
  Application,
  AppsDirectory,
  appsPageResponse,
  CloneCommand,
  Supplier,
} from "./Application";
import { APP_CONFIG } from "../../init";
import { APIClient } from "../../utilities/APIClient";

function getApp(appData: []): Application[] {
  return appData.map((appData: any) => {
    return {
      id: appData.id,
      name: appData.name,
      clientId: appData.clientId,
      supplierId: appData.supplierId,
      teamId: appData.teamId,
      status: "testing",
      supplierLogo: appData.supplierLogo,
      supplierName: appData.supplierName,
      identifier: appData.identifier,
      secondaryColor: appData.secondaryColor,
      countryCode: appData.countryCode,
      languageCode: appData.languageCode,
      enabledFeatures: [],
    };
  });
}

export class AppsDirectoryAPI extends APIClient implements AppsDirectory {
  findPaginated(
    firstIdx: number,
    appsPerPage: number
  ): Promise<appsPageResponse> {
    return this.fetch(
      `${APP_CONFIG.API_BASE_URI}/mobile_apps?page=${
        Math.floor(firstIdx / appsPerPage) + 1
      }`
    )
      .then((response) => {
        return response.json();
      })
      .then((appsData) => {
        return {
          items: getApp(appsData["hydra:member"]),
          stats: { totalCount: appsData["hydra:totalItems"] },
        };
      });
  }

  clone(cloneCommand: CloneCommand): Promise<boolean> {
    return this.fetch(`${APP_CONFIG.API_BASE_URI}/mobile_apps/clone`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(cloneCommand),
    })
      .then((response) => {
        if (response.status === 201) {
          return true;
        }
        throw new Error("Problème lors du clonage de l'application");
      })
      .then((triggerSucceeded) => triggerSucceeded);
  }

  updateConfig(
    appId: string,
    feature: string,
    value: string
  ): Promise<boolean> {
    const data = {
      appId: appId,
      feature: feature,
      value: value,
    };
    return this.fetch(`${APP_CONFIG.API_BASE_URI}/mobile_apps/app_configs`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => {
        if (response.status === 201) {
          return true;
        }
        return response.json().then((error) => {
          throw new Error(error["hydra:description"]);
        });
      })
      .then((triggerSucceeded) => triggerSucceeded);
  }

  getConfigs(appId: string): Promise<appConfigurationsBySections[]> {
    return this.fetch(
      `${APP_CONFIG.API_BASE_URI}/mobile_apps/${appId}/sectioned_configs`
    )
      .then((response) => {
        return response.json();
      })
      .then((sections) => {
        return sections["hydra:member"];
      });
  }

  triggerDeploy(app: Application) {
    return this.fetch(`${APP_CONFIG.API_BASE_URI}/mobile_app_builds`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ clientId: app.clientId }),
    })
      .then((response) => Promise.resolve(response.status === 201))
      .then((triggerSucceeded) => triggerSucceeded);
  }

  suppliers(): Promise<Supplier[]> {
    return this.fetch(`${APP_CONFIG.API_BASE_URI}/suppliers`)
      .then((response) => {
        return response.json();
      })
      .then((appsData) => {
        return appsData["hydra:member"];
      });
  }

  getOne(id: string): Promise<Application> {
    return this.fetch(`${APP_CONFIG.API_BASE_URI}/mobile_apps/${id}`)
      .then((response) => {
        return response.json();
      })
      .then((appsData) => {
        console.log(appsData);
        return appsData;
      });
  }
}
