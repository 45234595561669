import {
  cataloguesPageResponse,
  CataloguesProvider,
} from "./CataloguesProvider";
import { APP_CONFIG } from "../../init";
import { APIClient } from "../../utilities/APIClient";
import {
  Catalogue,
  catalogueConversionReport,
  catalogueId,
  dirbatImportFormat,
} from "./Catalogue";
import { DealersImportConversionReport } from "./Dealers";
import { ConversionReportNotAvailable } from "./ConversionReportNotAvailable";

export class APICataloguesProvider
  extends APIClient
  implements CataloguesProvider {
  constructor(token: string, clientId: string, private supplierId: string) {
    super(token);
  }
  findPaginated(
    firstIdx: number,
    cataloguesPerPage: number
  ): Promise<cataloguesPageResponse> {
    // cursor based pagination is not activated in the API, so we use page numbers instead
    const itemsPerPageInTheAPI = 30; //constrained by the API
    const pageNumber = Math.trunc(firstIdx / itemsPerPageInTheAPI) + 1;
    return this.fetch(
      `${APP_CONFIG.API_BASE_URI}/suppliers/${this.supplierId}/catalogues?page=${pageNumber}`
    )
      .then((response) => response.json())
      .then((cataloguesData) => {
        return {
          items: cataloguesData["hydra:member"],
          stats: { totalCount: cataloguesData["hydra:totalItems"] },
        };
      });
  }

  add(format: dirbatImportFormat, file: File): Promise<Catalogue> {
    const body = new FormData();
    body.append("format", format);
    body.append("file", file);
    return this.fetch(
      `${APP_CONFIG.API_BASE_URI}/suppliers/${this.supplierId}/catalogues/import`,
      {
        method: "POST",
        body: body,
      }
    )
      .then((response) => response.json())
      .then((catalogueData) => {
        return {
          title: catalogueData.title,
          status: catalogueData.status,
          format: catalogueData.format,
          id: catalogueData.id,
        };
      });
  }

  publish(catalogueId: catalogueId): Promise<Catalogue> {
    return this.fetch(
      `${APP_CONFIG.API_BASE_URI}/suppliers/${this.supplierId}/catalogues/${catalogueId}/publish`,
      {
        method: "POST",
      }
    )
      .then((response) => response.json())
      .then((catalogueData) => {
        return {
          title: catalogueData.title,
          status: catalogueData.status,
          format: catalogueData.format,
          id: catalogueData.id,
        };
      });
  }

  getReport(
    catalogueId: catalogueId,
    supplierId: string
  ): Promise<catalogueConversionReport> {
    return this.fetch(
      `${APP_CONFIG.API_BASE_URI}/suppliers/${supplierId}/catalogues/${catalogueId}/report`
    )
      .then((response) => {
        if (response.status === 404) {
          throw new ConversionReportNotAvailable(catalogueId);
        }
        return response.json();
      })
      .then((reportData) => reportData);
  }

  downloadFile(id: string, filename: string, supplierId: string): void {
    const link = document.createElement("a");
    this.fetch(
      `${APP_CONFIG.API_BASE_URI}/suppliers/${supplierId}/catalogues/${id}/download`
    )
      .then((res) => {
        return res.json();
      })
      .then((linkData) => {
        link.setAttribute("download", filename);
        link.href = linkData.url;
        link.click();
      })
      .catch((reason) => {
        alert(reason);
      });
  }

  getDealersImportReport(
    catalogueId: string
  ): Promise<DealersImportConversionReport> {
    return this.fetch(
      `${APP_CONFIG.API_BASE_URI}/suppliers/${this.supplierId}/dealer_imports/${catalogueId}/report`
    )
      .then((response) => {
        if (response.status === 404) {
          throw new ConversionReportNotAvailable(catalogueId);
        }
        return response.json();
      })
      .then((reportData) => reportData);
  }
}
