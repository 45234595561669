import {
  Contributor,
  ContributorsDirectory,
  contributorsPageResponse,
} from "./Contributors";
import { APP_CONFIG } from "../../init";
import { handleErrors } from "../../app/APIError";
import { APIClient } from "../../utilities/APIClient";
import { contributorDTO } from "./ContributorForm";

function getContributor(contributorData: []): Contributor[] {
  return contributorData.map((nd: any) => mapContributorFromApi(nd));
}

function mapContributorFromApi(cd: any): Contributor {
  return {
    id: cd.id,
    teamId: cd.team.id,
    lastname: cd.lastname,
    firstname: cd.firstname,
    email: cd.email,
    isAppOwner: cd.roles?.includes("ROLE_SUPPLIER_OWNER"),
    isTeamOwner: cd.roles?.includes("ROLE_TEAM_OWNER"),
  };
}

export class ContributorsDirectoryAPI
  extends APIClient
  implements ContributorsDirectory {
  findPaginated(
    teamId: string,
    firstIdx: number,
    contributorsPerPage: number
  ): Promise<contributorsPageResponse> {
    return this.fetch(
      `${APP_CONFIG.API_BASE_URI}/teams/${teamId}/users?page=${
        Math.floor(firstIdx / contributorsPerPage) + 1
      }`
    )
      .then((response) => {
        if (response.status >= 400) {
          throw new Error("Problème de récupération des utilisateurs");
        }
        return response.json();
      })
      .then((contributorsData) => {
        return {
          items: getContributor(contributorsData["hydra:member"]),
          stats: { totalCount: contributorsData["hydra:totalItems"] },
        };
      });
  }

  findOneById(id: string): Promise<Contributor> {
    return this.fetch(`${APP_CONFIG.API_BASE_URI}/users/${id}`)
      .then((response) => response.json())
      .then(
        (contributorData): Contributor => mapContributorFromApi(contributorData)
      );
  }

  delete(contributor: Contributor): Promise<boolean> {
    return this.fetch(`${APP_CONFIG.API_BASE_URI}/users/${contributor.id}`, {
      method: "delete",
    })
      .then((response) => {
        return response.status === 204;
      })
      .then((success): boolean => success);
  }

  save(dto: contributorDTO, teamId: string): Promise<Contributor> {
    const method = "POST";
    const endPointUrl = dto.id
      ? `${APP_CONFIG.API_BASE_URI}/teams/${teamId}/users/${dto.id}`
      : `${APP_CONFIG.API_BASE_URI}/teams/${teamId}/users`;
    const body = JSON.stringify({
      firstname: dto.firstname,
      lastname: dto.lastname,
      email: dto.email,
      teamId: dto.teamId,
    });
    const init: RequestInit = {
      method: method,
      headers: {
        "Content-Type": "application/json",
      },
      body: body,
    };
    return this.fetch(endPointUrl, init)
      .then(handleErrors)
      .then((response) => {
        return response.json();
      })
      .then((contributorData) => {
        return mapContributorFromApi(contributorData);
      })
      .catch((e: Error) => {
        return Promise.reject(JSON.parse(e.message)["hydra:description"]);
      });
  }
}
