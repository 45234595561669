import React, { useEffect, useState } from "react";
import { Route, Switch, useHistory, useParams } from "react-router-dom";
import ModuleRoutes from "./Routes";
import { useAuth } from "../../auth";
import { Badge } from "../../UIKit/Badge";
import { DateTime } from "luxon";
import {
  getBadgeType,
  getFlavorLabel,
  getPublicationDateLabel,
  getRemovalDateLabel,
  getStatusLabel,
  tableColumn,
} from "../../UIKit/Table";
import { FormattedMessage, useIntl } from "react-intl";
import { BONotificationPayload } from "../../app/Notifications";
import {
  EMPTY_EXHIBITION,
  Exhibition,
  ExhibitionCalendar,
  exhibitionDTO,
} from "./Exhibition";
import { ExhibitionsCalendarAPI } from "./ExhibitionsCalendarAPI";
import { ExhibitionForm } from "./ExhibitionForm";
import { ExhibitionsList } from "./ExhibitionsList";
import { Schedulable } from "../News/News";

const emptyExhibition: exhibitionDTO = {
  id: "",
  status: "draft",
  title: "",
  url: "",
  triggersNotification: false,
  start: DateTime.local(),
  end: DateTime.local(),
  location: "",
  publicationDate: DateTime.local(),
  removalDate: DateTime.local(),
};

export function ExhibitionsModule(props: {
  notify: (not: BONotificationPayload) => void;
}) {
  const history = useHistory();
  const auth = useAuth();
  const token = auth.user.token;
  const { clientId, supplierId } = useParams<{
    clientId: string;
    supplierId: string;
  }>();
  const calendar = new ExhibitionsCalendarAPI(token, clientId, supplierId);
  const intl = useIntl();
  const exhibitionsColumns: tableColumn<Exhibition>[] = [
    {
      label: intl.formatMessage({
        id: "exhibitions.title",
        defaultMessage: "Name of the exhibition",
      }),
      content: (exhibition: Exhibition) => exhibition.title,
    },
    {
      label: intl.formatMessage({
        id: "schedulable.status",
        defaultMessage: "Status",
      }),
      content: (item: Schedulable) => (
        <Badge
          label={getStatusLabel(item)}
          flavor={getFlavorLabel(item)}
          type={getBadgeType(item)}
        />
      ),
    },
    {
      label: intl.formatMessage({
        id: "schedulable.publication.date",
        defaultMessage: "Publication date",
      }),
      content: (item: Schedulable, locale) =>
        getPublicationDateLabel(item, locale),
    },
    {
      label: intl.formatMessage({
        id: "schedulable.removal.date",
        defaultMessage: "Removal date",
      }),
      content: (item: Schedulable, locale) => getRemovalDateLabel(item, locale),
    },
    {
      label: intl.formatMessage({
        id: "exhibitions.url",
        defaultMessage: "URL of the show, or your dedicated page",
      }),
      content: (exhibition: Exhibition) => (
        <a href={exhibition.url} target="_blank">
          {exhibition.url.slice(0, 30)}...
        </a>
      ),
    },
  ];

  return (
    <Switch>
      <Route path={ModuleRoutes(":clientId", ":supplierId").add} exact>
        <h1 className="text-2xl font-bold py-4 px-2 sm:px-0">
          <FormattedMessage
            id="exhibitions.add.title"
            defaultMessage="Add an exhibition"
          />
        </h1>
        <ExhibitionForm
          calendar={calendar}
          awaitsValidation={true}
          data={emptyExhibition}
          onSuccess={() => {
            history.push(ModuleRoutes(clientId, supplierId).exhibitions);
          }}
          notify={props.notify}
        />
      </Route>
      <Route path={ModuleRoutes(":clientId", ":supplierId").edit(":id")}>
        <h1 className="text-2xl font-bold py-4 px-2 sm:px-0">
          <FormattedMessage
            id="exhibitions.edit.title"
            defaultMessage="Edit an exhibition"
          />
        </h1>
        <EditExhibition
          calendar={calendar}
          notify={props.notify}
          clientId={clientId}
          supplierId={supplierId}
        />
      </Route>
      <Route path={ModuleRoutes(":clientId", ":supplierId").exhibitions} exact>
        <ExhibitionsList
          calendar={calendar}
          columns={exhibitionsColumns}
          clientId={clientId}
          supplierId={supplierId}
        />
      </Route>
    </Switch>
  );
}

function EditExhibition(props: {
  calendar: ExhibitionCalendar;
  notify: (not: BONotificationPayload) => void;
  clientId: string;
  supplierId: string;
}) {
  const [exhibition, setExhibition] = useState<Exhibition>(EMPTY_EXHIBITION);
  const { id } = useParams<{ id: string }>();
  useEffect(() => {
    (async () => setExhibition(await props.calendar.findOneById(id)))();
  }, [id]);
  const history = useHistory();

  const dto: exhibitionDTO = {
    id: exhibition.id,
    publicationDate: exhibition.publicationDate,
    removalDate: exhibition.removalDate,
    status: exhibition.status,
    triggersNotification: exhibition.triggersNotification,
    title: exhibition.title,
    url: exhibition.url,
    end: exhibition.end,
    location: exhibition.location,
    start: exhibition.start,
  };
  return (
    <ExhibitionForm
      calendar={props.calendar}
      awaitsValidation={true}
      data={dto}
      notify={props.notify}
      onSuccess={() => {
        history.push(
          ModuleRoutes(props.clientId, props.supplierId).exhibitions
        );
      }}
    />
  );
}
