import React, { useState } from "react";
import { Button } from "../../UIKit/Button";
import { Link } from "react-router-dom";
import { FileUploadZone } from "../../UIKit/form/FileUploadZone";
import { FormStepTitle } from "../../UIKit/form/FormStepTitle";
import { DealersProvider } from "./DealersProvider";
import { FormattedMessage, useIntl } from "react-intl";
import { RadioChoice } from "../../UIKit/RadioChoice";
import { dirbatImportFormat } from "./Catalogue";
import DocumentationRoutes from "../Documentation/Routes";
import { Toggle } from "../../UIKit/Toggle";
import { DownloadAssetLink } from "../Documentation/DocumentationModule";

function AltLink(props: { label: string; route: string }) {
  return (
    <Link to={props.route} className="text-blue-500 underline text-sm ">
      {props.label}
    </Link>
  );
}

export function DealersImportForm(props: {
  dealersImportProvider: DealersProvider;
  clientId: string;
  supplierId: string;
  onComplete: () => void;
}) {
  const [format, setFormat] = useState<dirbatImportFormat | undefined>(
    "dirbat_xls"
  );

  const [awaitsValidation, setAwaitsValidation] = useState<boolean>(true);
  const [file, setFile] = useState<File | undefined>(undefined);
  const [cleanAfterImport, setCleanAfterImport] = useState(false);
  const formIsValid = file !== undefined;

  function onFormatChoose(value: dirbatImportFormat) {
    setFormat(value);
  }

  function onFileUpdate(file: File) {
    setFile(file);
  }

  function onSubmit(e: Event) {
    e.preventDefault();
    setAwaitsValidation(false);
    if (formIsValid) {
      props.dealersImportProvider
        .add(file!, cleanAfterImport)
        .then((c) => props.onComplete());
    }
  }

  const intl = useIntl();
  let step = 1;
  return (
    <div>
      <h1 className="text-2xl font-bold py-4 px-2 sm:px-0">
        <FormattedMessage
          id="dealersImport.import.dealers"
          defaultMessage="Import dealers"
        />
      </h1>
      <div className="mb-4">
        <FormStepTitle
          label={intl.formatMessage({
            id: "catalogues.choose.format",
            defaultMessage: "Select a data format",
          })}
          step={step++}
          required={true}
          isValid={format !== undefined}
          awaitsValidation={awaitsValidation}
        />
        <div className="flex flex-row ">
          <RadioChoice
            label={intl.formatMessage({
              id: "catalogues.formats.dirbat_xls",
              defaultMessage: "xlsx",
            })}
            onCheck={onFormatChoose}
            value={"dirbat_xls"}
            checked={format === "dirbat_xls"}
          >
            <strong>
              {intl.formatMessage({
                id: "dealers.formats.dirbat_xls",
                defaultMessage: "Spreadsheet, one file",
              })}
            </strong>
            <p>
              {intl.formatMessage({
                id: "dealers.formats.dirbat_xls.explained",
                defaultMessage:
                  "DirBat XLS format uses Excel to manipulate your data.",
              })}
            </p>
            <DownloadAssetLink
              assetURL="/sample_dealers_dirbat_xls.xlsx"
              fileName="sample_dealers_dirbat_xls.xlsx"
              label={intl.formatMessage({
                id: "download.a.sample",
                defaultMessage: "download a sample",
              })}
            />{" "}
            |{" "}
            <AltLink
              label={intl.formatMessage({
                id: "dealers.access.documentation",
                defaultMessage: "access documentation",
              })}
              route={DocumentationRoutes(
                props.clientId,
                props.supplierId
              ).doc_import_dealers("dirbat_xls")}
            />
          </RadioChoice>
        </div>
      </div>
      <div className="mb-4">
        <FormStepTitle
          label={intl.formatMessage({
            id: "dealersImport.your.data",
            defaultMessage: "Your dealers data",
          })}
          step={step++}
          required={true}
          awaitsValidation={awaitsValidation}
          isValid={file !== undefined}
        />
        <FileUploadZone
          onFileUpdate={onFileUpdate}
          onClear={() => setFile(undefined)}
          label="catalogue.field.label.your.file"
          validationMessage="catalogues.field.file.is.mandatory"
          required={true}
          isValid={!!file}
          awaitsValidation={awaitsValidation}
        />
        <Toggle
          onToggle={() => {
            setCleanAfterImport(!cleanAfterImport);
          }}
          label={intl.formatMessage({
            id: "dealers.delete.after.import",
            defaultMessage:
              "Remove dealers that are not included in this new importation",
          })}
          toggled={cleanAfterImport}
        />
      </div>
      <Button
        onClick={(e) => onSubmit(e)}
        iconName="PlusIcon"
        label="dealers.import.file"
      />
    </div>
  );
}
